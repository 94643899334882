*{
    /* border: 0; */
    margin: 0;
    /* padding: 0; */
    box-sizing: border-box;
}
body{
    background-color: rgb(194, 194, 194);
}
.text{
    background-color: rgb(44, 44, 44);
    color: white;
    padding: 10px;
}